import classNames from 'classnames';
import React from 'react';

interface Props {
  type: 'good' | 'bad';
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

function Button({ type, text, disabled = false, onClick, className }: Props) {
  return (
    <button
      className={classNames(
        'p-2 m-2 w-48 h-16 rounded-lg border-2 disabled:bg-slate-600 disabled:border-slate-500 disabled:text-slate-400',
        className,
        {
          'bg-sky-600 hover:bg-sky-700 active:bg-sky-800': type === 'good',
          'bg-rose-700 hover:bg-rose-800 active:bg-rose-900': type === 'bad',
        },
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default Button;

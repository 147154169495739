import useInterval from '@use-it/interval';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectSecondsLeft,
  selectIsExpired,
  selectIsRunning,
  selectTock,
  tick,
} from '../redux/timeSlice';
import { makeDisplayTimeLeft } from '../util';

function TickTock() {
  const dispatch = useAppDispatch();

  const timeLeft = useAppSelector(selectSecondsLeft);
  const isRunning = useAppSelector(selectIsRunning);
  const isExpired = useAppSelector(selectIsExpired);
  const tock = useAppSelector(selectTock);

  useInterval(
    () => {
      dispatch(tick());
    },
    isRunning ? 1000 : null,
  );

  if (isRunning && isExpired) {
    document.title = tock ? "*** Time's Up ***" : "!!!! Time's Up !!!!";
  } else if (isRunning) {
    document.title = makeDisplayTimeLeft(timeLeft);
  } else {
    document.title = "It's a Timer";
  }

  return null;
}

export default TickTock;

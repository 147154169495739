import React from 'react';
import classNames from 'classnames';
import { useAppSelector } from '../redux/hooks';
import {
  selectSecondsLeft,
  selectIsExpired,
  selectIsRunning,
  selectProgress,
  selectTock,
} from '../redux/timeSlice';
import { makeDisplayTimeLeft } from '../util';

function Timer() {
  const isRunning = useAppSelector(selectIsRunning);
  const isExpired = useAppSelector(selectIsExpired);
  const timeLeft = useAppSelector(selectSecondsLeft);
  const progress = useAppSelector(selectProgress);
  const tock = useAppSelector(selectTock);

  const style = !isRunning || isExpired ? { left: 0 } : { left: `-${100 - progress}%` };

  return (
    <div className="w-full h-40 relative border-8 rounded-full bg-slate-600 overflow-hidden">
      {/* Progress background */}
      <div
        className={classNames('absolute w-full h-full inset-0 rounded-full', {
          'bg-emerald-700': isRunning && !isExpired,
          'bg-red-600': isRunning && isExpired && tock,
          'bg-red-800': isRunning && isExpired && !tock,
          'bg-slate-600': !isRunning,
        })}
        style={style}
      />
      <div className="flex items-center justify-center h-full">
        {isRunning && (
          <span className="inline-block align-middle z-30 text-5xl text-slate-300 font-mono">
            {!isExpired && makeDisplayTimeLeft(timeLeft, true)}
            {isExpired && "Time's Up"}
          </span>
        )}
      </div>
    </div>
  );
}

export default Timer;

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import TimeField from 'react-simple-timefield';
import Button from './Button';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { selectIsRunning, startTimer, stopTimer, setDuration } from '../redux/timeSlice';

function Controls() {
  const dispatch = useAppDispatch();

  const isRunning = useAppSelector(selectIsRunning);
  const [durationDisplay, setDurationDisplay] = useState('02:00:00');

  const start = useCallback(() => {
    // 2 hours
    dispatch(startTimer());
  }, [dispatch]);

  const stop = useCallback(() => {
    dispatch(stopTimer());
  }, [dispatch]);

  const onChangeDuration = useCallback(
    (event, time) => {
      setDurationDisplay(time);
      // convert dumb string to ms
      let parts = time.split(':');
      parts = parts.map((segment: string) => parseInt(segment, 10));
      const ms = 1000 * (parts[0] * 60 * 60 + parts[1] * 60 + parts[2]);

      dispatch(setDuration(ms));
    },
    [dispatch],
  );

  const customInput = (
    <input
      type="text"
      className={classNames(
        'text-slate-800 text-lg bg-slate-300 font-mono w-24 h-12 m-4 border-2 border-slate-500 text-center rounded-lg disabled:bg-slate-600',
      )}
      disabled={isRunning}
    />
  );

  return (
    <div>
      <Button className="mr-20" type="good" text="Start" onClick={start} disabled={isRunning} />
      <Button type="bad" text="Stop" onClick={stop} disabled={!isRunning} />
      <br />
      <TimeField
        showSeconds
        onChange={onChangeDuration}
        value={durationDisplay}
        input={customInput}
      />
    </div>
  );
}

export default Controls;

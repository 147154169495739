import React from 'react';
import Controls from './components/Controls';
import TickTock from './components/TickTock';
import Timer from './components/Timer';

function App() {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center text-center text-white h-screen font-sans">
      <div className="mt-5 text-8xl font-medium">It&apos;s a Timer</div>
      <div className="mt-10 mb-10 w-full">
        <Timer />
      </div>
      <Controls />
      <TickTock />
    </div>
  );
}

export default App;

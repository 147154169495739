/* eslint-disable import/prefer-default-export */

const padZeroAbs = (num: number) => {
  const absNum = Math.abs(num);
  return absNum < 10 ? `0${absNum}` : `${absNum}`;
};

export const makeDisplayTimeLeft = (secondsLeft: number, includeSeconds = false) => {
  const minutesLeft = secondsLeft / 60;

  const hours = Math.floor(minutesLeft / 60);
  const minutes = Math.floor(minutesLeft % 60);
  const seconds = Math.floor(secondsLeft % 60);

  if (includeSeconds) {
    return `${hours} : ${padZeroAbs(minutes)} : ${padZeroAbs(seconds)}`;
  }
  return `${hours} : ${padZeroAbs(minutes)}`;
};
